@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    #root {
        width: 100%;
        height: 100%;
    }

    h1 {
        font-size: 48px;
        font-weight: 300;
    }
    h2 {
        font-size: 36px;
        font-weight: 300;
    }

    .btn {
        @apply py-1 px-3 rounded;
        cursor: pointer;
    }
    .btn-sm {
        @apply py-1 px-1;
        @apply text-sm;
    }
    .btn-xs {
        @apply py-1 px-1;
        @apply text-xs;
    }
    .btn-default {
        @apply bg-gray-600 text-white;
    }
    .btn-default:hover {
        @apply bg-gray-700;
    }
    .btn-primary {
        @apply bg-blue-700 text-white;
    }
    .btn-primary:hover {
        @apply bg-blue-800;
    }

    .spinner {
        @apply border-t border-blue-700;
        animation: spinner 0.5s linear infinite;
    }
    .btn .spinner {
        @apply border-white;
    }
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    [type='file'],
    [type='color'] {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;
    }

    [type='file'] + label {
        /* File upload button styles */
    }

    [type='file']:focus + label,
    [type='file'] + label:hover {
        /* File upload hover state button styles */
    }

    [type='file']:focus + label {
        /* File upload focus state button styles */
    }

    .input-color-container label {
        min-width: 40px;
    }
    .input-color-container:focus-within {
        box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    }

    .disabled {
        opacity: 0.5;
    }

    .edit-mode main {
        /* margin-top: 48px; */
    }

    .image-stack {
        position: relative;
    }
    .image-stack img:nth-child(1) {
        position: absolute;
        top: 5px;
        left: -7px;
        transform: rotate(-3deg);
    }
    .image-stack img:nth-child(2) {
        position: absolute;
        top: 5px;
        left: 0px;
        transform: rotate(6deg);
    }
    .image-stack img:nth-child(3) {
        position: absolute;
        top: 0;
        left: 0;
    }

    #sequence-nav {
        z-index: 10;
    }
    #mouse-target {
        z-index: 9;
    }
    .click-zone {
        z-index: 10;
    }
    .click-zone svg {
        pointer-events: none;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
    .click-zone svg path {
        pointer-events: all;
    }
    /* .click-zone svg { */
    /* filter: drop-shadow(1px 1px 0px #111) drop-shadow(-1px 1px 0px #111) drop-shadow(1px -1px 0px #111) drop-shadow(-1px -1px 0px #111); */
    /* } */
    .click-zone path {
        opacity: 0.5;
        /* fill: #d43436 !important; */

        fill: none;
        /* stroke: #d43436 !important; */
        /* stroke-width: 5px; */
        stroke-linejoin: round;
    }
    .click-zone path:hover {
        opacity: 0.6;
        cursor: pointer;
    }
    .click-zone-thumbnail svg {
        max-width: 135px;
        max-height: 135px;
        width: 135px;
        height: 135px;
        object-fit: contain;
    }

    .details-pane {
        position: fixed;
        z-index: 10;
    }
    .details-pane-top {
        position: fixed;
    }
    .details-pane-right {
        position: fixed;
    }
    .details-pane-bottom {
        position: fixed;
    }
    .details-pane-left {
        left: 0;
        top: 0;
        height: 100vh;
        width: 350px;
        background-color: white;
        @apply shadow-2xl;
    }
    .details-pane-tooltip > div {
        padding: 2rem;
        background: white;
        width: 12rem;
    }

    .admin-sequence-overlay {
        z-index: 20;
    }
}
